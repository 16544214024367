.home {
    background: #FAFAFA;
    width: 100vw;

    .header {
        height: 56px;
        background-color: white;
    }

    .menu {
        display: inline-block;
        color: #666;
        font-size: 14px;
        text-decoration: none;
        background-color: transparent;
    }

    .menu:hover {
        color: #0d53fb;
    }

    .container {
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        width: 1200px;
    }

    .logo {
        height: 30px;
    }

    .search {
        height: 380px;
        background-color: #1e49b9;
        background: url('../../images/bg-search.png') no-repeat top;
        background-size: cover;
    }

    .title {
        font-size: 46px;
        font-family: "Microsoft YaHei", Arial;
        color: #fff;
        margin-bottom: 40px;
        text-align: center;
        padding-top: 60px;

    }

    .search-type {
        margin-right: 40px;
        color: #f9f9f9;
        padding: 5px 8px;
        border-radius: 2px;
    }

    .search-type:last-child {
        margin-right: 0px;
    }

    .search-type.active,
    .search-type:hover {
        color: #128bed;
        background-color: #fff;
        position: relative;
    }

    .search-input {
        border-radius: 0px;
        height: 50px;
        width: 800px;
        font-size: 16px;
    }

    .search-btn {
        height: 50px;
        border-radius: 0px;
        font-weight: 400;
        font-size: 18px;
        width: 100px;
    }

    .func-item {
        width: 225px;
        height: 95px;
        display: flex;
        align-items: center;
        padding: 20px;
    }

    .func-img {
        width: 36px;
        height: 36px;
        margin-right: 5px;
    }

    .func-title {
        font-size: 18px;
        font-weight: 700;
    }

    .func-subtitle {
        font-size: 13px;
        color: #999;
        margin-top: 5px;
    }

    .tabs {
        font-size: 25px;
    }

    .city-title {
        width: 90px;
        font-weight: 700;
        color: #333;
        font-size: 16px;
        flex-shrink: 0;
    }

    .city-title:hover {
        color: #0d53fb;
    }

    .area-box {
        margin-bottom: 20px;
        border-bottom: 1px solid #f2f2f2;
        flex-grow: 1;
    }

    .area {
        display: inline-block;
        color: #666;
        font-size: 16px;
        margin-right: 40px;
        margin-bottom: 20px;
        text-decoration: none;
        background-color: transparent;
    }

    .area:hover {
        color: #0d53fb;
    }

    .pro-title {
        width: 300px;
        font-weight: 700;
        color: #333;
        font-size: 16px;
        flex-shrink: 0;
    }

    .pro-title:hover {
        color: #0d53fb;
    }

    .company-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 40px;
        row-gap: 40px;
        overflow: auto;
        margin-bottom: 30px;
    }

    .company {
        overflow: hidden;
    }

    .company-name {
        font-weight: 700;
        color: #333;
        font-size: 16px;
    }

    .company-info {
        color: #7a808f;
        margin-top: 5px;
    }

    .company-address {
        color: #7a808f;
        margin-top: 5px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .person-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        row-gap: 30px;
        margin-bottom: 30px;
    }

    .person:before {
        content: "";
        display: inline-block;
        width: 6px;
        height: 6px;
        margin-right: 10px;
        background-color: #d8d8d8;
        border-radius: 50%;
        vertical-align: middle;
    }

    .person-name {
        color: #333;
    }

    .fb-bg {
        background-image: url(../../images/foot.png);
        background-repeat: no-repeat;
        background-size: cover;
    }

    .fb-icon {
        width: 20px;
        height: 20px;
    }

    .fb-title {
        margin-left: 5px;
        font-size: 16px;
        font-weight: 700;
        line-height: 1;
        color: #fff;
    }

    .fb-subtitle {
        color: #b6cdf8;
        font-size: 13px;
        margin-top: 5px;
    }

    .footer {
        background-color: #222a37;
    }

    .fast-navi {
        font-weight: 700;
        color: #fff;
        font-size: 16px;
    }

    .navi-link {
        color: #7a808f;
        text-decoration: none;
        background-color: transparent;
    }

    .navi-link:hover {
        color: #0d53fb;
    }

    .link-list {
        grid-auto-flow: column;
        column-gap: 20px;
        row-gap: 10px;
        grid-template-rows: repeat(5, 1fr);
        display: grid;
        font-size: 12px;
        margin-top: 15px;
    }

    .link-list-1 {
        grid-auto-flow: column;
        column-gap: 20px;
        row-gap: 10px;
        grid-template-rows: repeat(4, 1fr);
        display: grid;
        font-size: 12px;
        margin-top: 15px;
    }

    .data-box {
        margin-top: 80px;
        font-size: 12px;
        color: #7a808f;
    }

    .link-box {
        color: #7a808f;
        font-size: 12px;
        margin-top: 10px;
    }
}

body {
    background: #FAFAFA !important;
}

.modal-text {
    margin-top: 30px;
    color: #333;
    text-align: center;
    font-size: 16px;
}

.qr-code {
    margin-top: 36px;
    width: 200px;
    height: 200px;
    color: #7a808f;
}

.menu-1-title {
    font-weight: 600;
    color: #00000a;
    font-size: 14px;
    margin-bottom: 4px;
}

.menu-1-subtitle {
    color: #76788b;
    font-size: 12px;
}

.menu-1-item {
    padding: 8px 12px;
    border-radius: 4px;
}

.menu-1-item:hover {
    color: #0d53fb;
    background-color: #f4f5fb;
}

.menu-2-item {
    color: #333;
    padding: 8px 0px;
}

.menu-2-item:hover {
    color: #0d53fb;
}

.menu-section {
    font-size: 16px;
    font-weight: 700;
    color: black;
}
